<template>
  <div>
    <el-button :type="buttonType" :size="buttonSize" :icon="buttonIcon" :disabled="buttonDisabled" @click="dialog = true" v-if="showButton">{{ buttonText }}</el-button>

    <el-dialog :visible.sync="dialog" title="零售信息" append-to-body :close-on-click-modal="false" @closed="handleClosed" width="1200px">
      <retail-single-analy @drill="handleGoodsDrill" />
    </el-dialog>

    <el-dialog :visible.sync="detail.show" title="零售商品明细" append-to-body :close-on-click-modal="false" @closed="handleClosed" width="1330px">
      <el-table ref="list" :data="data" border highlight-current-row empty-text="没有相关零售商品明细" height="600" @sort-change="handleSort" @selection-change="handleSelectionChange" v-loading="loading">
        <el-table-column type="selection" width="35" :selectable="checkSelectable" />
        <el-table-column label="采购状态" width="80">
          <template slot-scope="scope">
            <dot :type="scope.row.isPurchased ? 'success' : 'gray'" same>{{scope.row.isPurchased ? '已采购' : '未采购'}}</dot>
          </template>
        </el-table-column>
        <el-table-column prop="formCode" label="销售单号" width="130" sortable="custom" />
        <el-table-column prop="contractNo" label="合同号" width="100" show-overflow-tooltip />
        <el-table-column prop="status" label="订单状态" width="100" align="center">
          <template slot-scope="scope">
            <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="客户" width="140" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.customerName }}({{scope.row.customerPhone}})</template>
        </el-table-column>
        <el-table-column prop="erpCode" label="ERP编码" width="130" />
        <el-table-column prop="goodsName" label="商品" min-width="180" show-overflow-tooltip sortable="custom">
          <div style="line-height: 1.5" slot-scope="scope">
            <div>{{ scope.row.goodsName }}</div>
            <div class="fc-g">{{ $goodsSpecConvert(scope.row.specs) }}</div>
          </div>
        </el-table-column>
        <el-table-column prop="quantity" label="数量" align="center" width="70" />
        <el-table-column prop="deliveryDate" label="交货日期" align="center" width="100" :formatter="r => { return new Date(r.deliveryDate).format('yyyy/MM/dd'); }" sortable="custom" />
        <el-table-column prop="sendCount" label="已发货" align="center" width="70" />
        <el-table-column prop="info" label="备注" width="130" show-overflow-tooltip />
      </el-table>
      <div style="margin-top: 12px" v-if="multiple">
        <el-button type="primary" :disabled="loading || !selection || !selection.length" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import retailSingleAnaly from "./retail-single-analy";
import initData from "@/mixins/initData";

export default {
  components: { retailSingleAnaly },
  mixins: [initData],
  props: {
    showButton: {
      type: Boolean,
      default: true,
    },
    buttonType: {
      type: String,
      default: "primary",
    },
    buttonIcon: String,
    buttonSize: {
      type: String,
      default: "small",
    },
    buttonText: {
      type: String,
      default: "零售信息",
    },
    buttonLoading: Boolean,
    buttonDisabled: Boolean,
    multiple: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "选择零售单商品",
    },
    keepQuery: Boolean,
    tip: String,
    excludeKeys: Array,
    supplier: String | Number,
  },
  data() {
    return {
      dialog: false,
      inited: false,
      selection: null,
      current: null,
      loading: false,
      // sort: "deliveryDate,asc",
      query: {
        deliveryDateMin:null,
        deliveryDateMax: null
      },
      detail: {
        show: false,
        goods: null,
      },
      status: {
        // input: { key: "input", label: "录入状态", type: "info" },
        // submit: { key: "submit", label: "提交状态", type: "" },
        // auditing: { key: "auditing", label: "审核中", type: "" },
        pass: { key: "pass", label: "审核通过", type: "success" },
        // fail: { key: "fail", label: "审批未通过", type: "danger" },
        // callback: { key: "callback", label: "退回修改", type: "info" },
        sendPart: { key: "sendPart", label: "部分已发货", type: "success" },
        sended: { key: "sended", label: "已发货", type: "success" },
        closedPart: { key: "closedPart", label: "部分关闭", type: "info" },
        // closed: { key: "closed", label: "已关闭", type: "info" },
        // cancel: { key: "cancel", label: "已取消", type: "info" },
      },
    };
  },
  methods: {
    handleGoodsDrill(goods, deliveryDateMin, deliveryDateMax) {
      if (goods) {
        this.query.deliveryDateMin = deliveryDateMin;
        this.query.deliveryDateMax = deliveryDateMax;
        this.detail.goods = goods;
        this.detail.show = true;
        this.toQuery();
      }
    },
    afterLoad() {
      this.$nextTick(() => {
        this.$refs.list && this.$refs.list.toggleAllSelection();
      });
    },
    checkSelectable(row) {
      let enable = row.allowPurchase && row.quantity - row.cancelCount > 0;
      if (enable && this.excludeKeys && this.excludeKeys.length) {
        enable =
          this.excludeKeys.findIndex((ek) => {
            return row.formId + "_" + row.goodsId === ek;
          }) < 0;
      }
      return enable;
    },
    handleSort(sort) {
      if (sort.order == null) this.sort = "";
      else
        this.sort =
          sort.prop + "," + (sort.order === "ascending" ? "asc" : "desc");
      this.toQuery();
    },
    checkItemStatus(scope) {
      return scope.row.quantity - scope.row.sendCount > 0
        ? ""
        : "common-gift-goods-row";
    },
    beforeInit() {
      this.$refs.list && this.$refs.list.clearSelection();
      if (this.detail.goods && this.detail.goods.length) {
        this.url = "api/shop/orderFormFull/purchase/itemGoods";
        this.params = Object.assign(
          {
            supplier: this.supplier,
            goodsIds: this.detail.goods.map((r) => r.goodsId),
          },
          this.query
        );
        this.inited = true;
        return true;
      } else {
        return false;
      }
    },

    handleSelectionChange(s) {
      if (this.multiple) {
        this.selection = s;
      }
    },
    handleClosed() {
      this.selection = null;
      this.$refs.list && this.$refs.list.setCurrentRow();
      this.$refs.list && this.$refs.list.clearSelection();
    },
    handleSubmit() {
      if (this.selection && this.selection.length) {
        let arr = this.selection.map((o) => {
          return {
            id: o.goodsId,
            specs: o.specs,
            name: o.goodsName,
            code: o.code,
            erpCode: o.erpCode,
            purchaseLimit: false,
            count: o.quantity,
            source: [o.id],
            retailFormCode: [o.formCode],
            retailContractNo: [o.contractNo],
            shop: [o.shopName],
            info: o.info,
            customer: [o.customerName],
          };
        });
        this.$emit("submit", arr);
        this.detail.show = false;
      }
    },
  },
};
</script>